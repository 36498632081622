import React, { useEffect, useState } from "react";
import { useMetricsContext } from "../../../contexts/MetricsContext";
import { useAppContext } from "../../../contexts/App";
import { Link, useLocation } from "react-router-dom";
import { NotificationBlock } from "../../../contexts/VisitorContext";
import { BaseButton } from "../button/BaseButton";
import YandexIcon from "../../icons/YandexIcon";
import YandexWhiteIcon from "../../icons/YandexWhiteIcon";
import StarRatingIcon from "../../icons/StarRatingIcon";
import logoFooter2 from "../../../images/logoFooter2.svg";
import borderPhone from "../../../images/borderPhone.png";
import logoFooter2Light from "../../../images/logoFooter2Light.svg";
import AppStore from "../../icons/AppStore";
import GooglePlayImg from "../../../images/googlePlayFooter.png";
import RuStore from "../../icons/RuStore";
import TelegramRoundIcon from "../../icons/TelegramRoundIcon";
import VkRoundIcon from "../../icons/VkRoundIcon";
import QRCode from "react-qr-code";
import GisWidget from "../GisWidget";


export default function Footer() {
  const { company, branch, city, yandexRating } = useAppContext()
  let location = useLocation();

  const [showInstallApp, setShowInstallApp] = useState(false);

  useEffect(() => {
    console.log(location.pathname)
    if (
      location.pathname?.split('/')?.pop() !== "cart" && 
      !location.pathname.includes("/order/") &&
      company?.template?.appMobileTitle &&
      (company.iosAppLink || company.androidAppLink || company.rustoreAppLink)
    ) {
      setShowInstallApp(true);
    }
  }, [location.pathname, company]);

  const theme = company?.template?.theme;

  const { metricsEvent } = useMetricsContext();

  return (
    <footer
      className={`overflow-hidden bg-gray-10 px-[15px] dark:bg-gray-50 md:flex-row shadow-[0px_-4px_80px_rgba(0,0,0,0.06)] ${showInstallApp && "pt-[30px] lg:pt-[50px]"}`}
    >
      <NotificationBlock
        place="footer"
        classNameWrapper={`md:mb-[30px] mb-[20px] ${!showInstallApp && "mt-[50px]"}`}
      />
      <div className="container">
        {showInstallApp && (
          <div className="overflow-hidden lg:rounded-[45px] rounded-[24px] bg-orderbtn md:min-h-[370px]">
            <div
              className={`flex`}
            >
              {!!company?.footerScreenshot && (
                <div className="relative hidden overflow-hidden md:flex min-h-[370px] min-w-[390px] self-end">
                  <div className="relative w-full self-end">
                    <div className="absolute left-[85px] bottom-[-314px] z-0 rotate-[-8deg] rounded-[30px] overflow-hidden">
                      <img
                        className=""
                        src={company.footerScreenshot}
                        alt="Мобильная версия сайта"
                        width={270}
                        height={585}
                      />
                    </div>
                    <div className="absolute bottom-[-300px] left-[70px] z-10 rotate-[-8deg]">
                      <img src={borderPhone} alt="" className="h-[588px] w-[295px]" />
                    </div>
                  </div>
                </div>
              )}
              {!!company?.template?.appMobileTitle && (
                <div className={`my-10 mx-[20px] md:mr-[50px] ${!company?.footerScreenshot && 'md:ml-[50px]'}`}>
                  <p className={`font-medium md:text-[36px] text-[20px] tracking-[-0.36px] md:leading-[40px] leading-[20px] ${!company?.footerScreenshot ? 'text-center' : 'md:text-start text-center'}`}>
                    {company.template.appMobileTitle}
                  </p>

                  {!!company?.template?.appMobileText && (
                    <p className={`mt-[5px] md:text-[18px] text-[14px] text-main md:leading-[27px] leading-[16px] ${!company?.footerScreenshot ? 'text-center' : 'md:text-start text-center'}`}>
                      {company.template.appMobileText}
                    </p>
                  )}

                  <div className={`mt-[30px] flex gap-x-[35px] ${!company?.footerScreenshot ? 'justify-center' : 'md:justify-start justify-center'}`}>
                    <div className="">
                      {!!company?.iosAppLink && (
                        <a
                          href={company.iosAppLink}
                          onClick={() => metricsEvent("app-icon")}
                          target="_blank"
                          className="mb-[15px] block md+:mb-[11px]"
                          rel="noreferrer"
                        >
                          <BaseButton className="md:w-[254px] w-full h-[40px] rounded-[42px] flex justify-center items-center bg-white">
                            <div className="w-[107px]">
                              <AppStore />
                            </div>
                          </BaseButton>
                        </a>
                      )}
                      {!!company?.androidAppLink && (
                        <a
                          href={company.androidAppLink}
                          onClick={() => metricsEvent("app-icon")}
                          target="_blank"
                          className="mb-[15px] block md+:mb-[11px]"
                          rel="noreferrer"
                        >
                          <BaseButton className="md:w-[254px] w-full h-[40px] rounded-[42px] flex justify-center items-center bg-white">
                            <img className="w-[120px]" src={GooglePlayImg} alt="" />
                          </BaseButton>
                        </a>
                      )}
                      {!!company?.rustoreAppLink && (
                        <a
                          href={company.rustoreAppLink}
                          onClick={() => metricsEvent("app-icon")}
                          target="_blank"
                          className="block"
                          rel="noreferrer"
                        >
                          <BaseButton className="md:w-[254px] w-full h-[40px] rounded-[42px] flex justify-center items-center bg-white">
                            <RuStore />
                          </BaseButton>
                        </a>
                      )}
                    </div>

                    {!!company?.footerQrCodeLink && (
                      <div className="hidden md+:block">
                        <div className="bg-white p-2 rounded-[12px]">
                          <QRCode value={company.footerQrCodeLink} size={144} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div>
          <div className="">
            <div className="flex md:flex-row flex-col md:items-start items-center md:justify-between mt-[40px]">
              <div>
                <div className="font-medium text-[22px] md:text-[30px] mb-[6px] md:text-start text-center">Связаться с нами</div>
                <a className="block font-medium text-[14px] md:text-[20px] md:text-start text-center" href={'tel:' + branch?.contacts?.at(0)?.phone}>{branch?.contacts?.at(0)?.phone}</a>
              </div>

              <div className="md:hidden w-full h-[1px] my-5 bg-[#EFEFEF] dark:bg-darkGray"></div>

              <div className="flex flex-wrap md:gap-x-[50px] md:w-[252px] w-full md:px-0 px-[50px]">
                <Link className="md:w-[101px] w-[33%] font-medium md:text-[20px] text-[14px]" to={`/${city.slug}/about`}>О нас</Link>
                <Link className="md:w-[101px] w-[33%] font-medium md:text-[20px] text-[14px]" to={`/${city.slug}/stock`}>Акции</Link>
                <Link className="md:w-[101px] w-[33%] font-medium md:text-[20px] text-[14px]" to={`/${city.slug}/payment`}>Доставка</Link>
                <Link className="md:w-[101px] w-[33%] font-medium md:text-[20px] text-[14px]" to={`/${city.slug}`}>Каталог</Link>
                <Link className="md:w-[101px] w-[33%] font-medium md:text-[20px] text-[14px]" to={`/${city.slug}/reviews`}>Отзывы</Link>
                <Link className="md:w-[101px] w-[33%] font-medium md:text-[20px] text-[14px]" to={`/${city.slug}/payment`}>Оплата</Link>
              </div>

              <div className="md:hidden w-full h-[1px] my-5 bg-[#EFEFEF] dark:bg-darkGray"></div>

              <div className="md:block flex justify-between md:w-auto w-full md:mb-0 mb-5">
                {!!yandexRating && (
                  <div className="h-[55px] w-[150px] rounded-[5px] bg-yandexRating p-[10px] dark:bg-gray-40 md:mb-[10px]">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center text-base font-medium">
                        <span className="mr-1">
                          {!(theme === "dark") && <YandexIcon />}
                          {theme === "dark" && <YandexWhiteIcon />}
                        </span>
                        <span className="inline-block w-[4ch]">
                          {!yandexRating ||
                          Number(yandexRating).toFixed(2) === "0.00"
                            ? ""
                            : Number(yandexRating).toFixed(2)}
                        </span>
                      </div>
                      <div className="flex items-center">
                        {[...Array(5)].map((item) => (
                          <StarRatingIcon key={item} />
                        ))}
                      </div>
                    </div>
                    <div className="mt-[4px] text-[8px] font-normal">
                      <p>Рейтинг организации в Яндексе</p>
                    </div>
                  </div>
                )}
                <GisWidget/>
              </div>

              <div className="md:w-auto w-full flex justify-between items-center rounded-[24px] px-[21px] py-[14px] md:p-0 md:bg-transparent md:dark:bg-transparent bg-orderbtn dark:bg-lightBlack">
                <div className="md:hidden font-medium">Мы в социальных <br /> сетях</div>
                <div className="flex justify-between gap-x-[10px] lg:gap-x-[16px]">
                  {!!branch?.telegramLink && (
                    <a
                      href={branch.telegramLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TelegramRoundIcon className="w-[35px] h-[35px] md+:w-[62px] md+:h-[62px]" />
                    </a>
                  )}
                  {!!branch?.vkCommunities?.at(0) && (
                    <a
                      href={`https://vk.com/club${branch?.vkCommunities?.at(0)?.groupId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <VkRoundIcon className="w-[35px] h-[35px] md+:w-[62px] md+:h-[62px]" />
                    </a>
                  )}
                </div>
              </div>
            </div>
            
            <div className="mt-[40px] mb-[31px] grid grid-cols-1 gap-y-5 md:mb-[21px] md:grid-cols-[243px_1fr_183px] md:gap-y-0">
              <div className="order-2 bg-transparent md:order-1">
                <div className="flex flex-col items-center text-[13px] md:items-start md:text-base">
                  <a
                    href={`/${city.slug}/personalDataPolicy`}
                    target="_blank"
                    className="text-main underline"
                    rel="noreferrer"
                  >
                    Политика конфиденциальности
                  </a>
                  <a
                    href={`/${city.slug}/termsOfUse`}
                    target="_blank"
                    className="text-main underline"
                    rel="noreferrer"
                  >
                    Пользовательское соглашение
                  </a>
                </div>
              </div>
              <div className="order-3 flex items-end justify-center bg-transparent md:order-2 px-5">
                <p className="text-[10px] font-normal text-footerBlack dark:text-footerWhite md:text-base text-center">
                  Copyright &copy; {new Date().getFullYear()} «{company.title}
                  ». Все права защищены
                </p>
              </div>

              <div className="order-1 bg-transparent text-center md:order-3 md:text-right">
                <div className="text-base font-normal dark:text-gray-10">
                  Работает на технологии
                </div>
                {company?.template?.theme === "dark" && (
                  <Link
                    to={"/"}
                    target="_blank"
                    className="flex justify-center md:justify-end"
                  >
                    <img src={logoFooter2} className="" alt="" />
                  </Link>
                )}
                {company?.template?.theme === "light" && (
                  <Link
                    to={"/"}
                    target="_blank"
                    className="flex justify-center md:justify-end"
                  >
                    <img src={logoFooter2Light} className="" alt="" />
                  </Link>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </footer>
  );
}
