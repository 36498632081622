import React, { useEffect, useState, useRef, useMemo } from "react";
import Slider from "react-slick";
import { useAppContext } from "../../../../contexts/App";
import { useCartContext } from "../../../../contexts/CartContext";
import { apiClient } from "../../../../libs/api/apiClient";
import { findDiffDate } from "../../../../libs/helpers/findDiffDate";
import CloseIcon from "../../../icons/CloseIcon";
import { BaseButton } from "../../../common/button/BaseButton";
import { Preloader } from "../../../common/preloader";
import ArrowIcon from "../../../icons/ArrowIcon";
import { useProductDetailCardContext } from "../../../../contexts/ProductDetailCardContext";
import { resizeImage } from "../../../../libs/helpers/resizeImage";
import { formatCurrency } from "../../../../libs/helpers/formatCurrency";
import mainStock from "../../../../images/mainStock.jpg";
import time from "../../../../images/time.svg";
import productImgStub from "../../../../images/productImgStub.jpg";


interface Props {
  close: () => void;
  stockId: any;
  stocks: any[];
  isActive?: boolean;
}
interface Button {
  url: string;
  title: string;
}

export default function DetailStock({
  stockId,
  stocks,
  close,
  isActive
}: Props) {
  const { branch } = useAppContext()
  const [stock, setStock] = useState<any>(null);
  const [timeToEnd, setTimeToEnd] = useState<any>(null);
  const [bgColor, setBgColor] = useState("main");
  const refDescriptionDesc = useRef<any>(null);
  const refDescriptionMob = useRef<any>(null);
  const { cart, loading, updateCart } = useCartContext();

  const [isShowLeftArrow, setIsShowLeftArrow] = useState(false);
  const [isShowRightArrow, setIsShowRightArrow] = useState(false);

  useEffect(() => {
    if (stock?.goods?.length > 4) {
      setIsShowRightArrow(true);
      setIsShowLeftArrow(true);
    } else {
      setIsShowRightArrow(false);
      setIsShowLeftArrow(false);
    }
  }, [stock?.goods?.length]);

  useEffect(() => {
    setStock(null);
    const candidatStock = stocks.filter((s) => s.id == stockId)?.at(0);
    if (candidatStock) {
      setStock(candidatStock);
    } else {
      apiClient.stocks.getStock(branch.id, stockId).then((data: any) => {
        if (data.status !== 200) {
          close();
          return;
        }

        setStock(data);
      });
    }
  }, [stockId]);

  useEffect(() => {
    if (stock) {
      if (refDescriptionDesc.current)
        refDescriptionDesc.current.innerHTML = stock.description;
      if (refDescriptionMob.current)
        refDescriptionMob.current.innerHTML = stock.description;
      setTimeToEnd(findDiffDate(stock.endAt));
      setBgColor(
        findDiffDate(stock.endAt).days || findDiffDate(stock.endAt).hours > 2
          ? "additional"
          : "main"
      );
    } else {
      setTimeToEnd("");
      setBgColor("main");
    }
  }, [stock]);

  useEffect(() => {
    if (isActive) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [isActive]);

  const slider = useRef<any>(null);
  const sliderMobile = useRef<any>(null);

  const [isSwiping, setIsSwiping] = useState(false);
  const [isSwipingMobile, setIsSwipingMobile] = useState(false);

  const settingsSlider = {
    slidesToShow: 1,
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToScroll: 4,
    variableWidth: true,
    beforeChange: () => setIsSwiping(true),
    afterChange: () => setTimeout(() => setIsSwiping(false), 100)
  };

  const settingsSliderMobile = {
    slidesToShow: 1,
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToScroll: 2,
    variableWidth: true,
    beforeChange: () => setIsSwipingMobile(true),
    afterChange: () => setTimeout(() => setIsSwipingMobile(false), 100)
  };

  return (
    <>
      {/* Декстоп */}
      <div
        className={
          "max-w-screen fixed left-0 top-0 z-[200] hidden h-screen w-full overflow-y-auto sm:block " +
          (!stockId && "hidden")
        }
      >
        <div className="relative flex h-full w-full items-center justify-center p-4">
          <div
            onClick={close}
            className="absolute left-0 top-0 h-full min-h-screen w-full bg-[#bdbdbd9e] dark:bg-[#0c0c0cc7]"
          />
          <div className="relative">
            <div
              onClick={close}
              className="absolute right-[0px] top-[0px] z-10 flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full bg-main"
            >
              <CloseIcon
                colorClassName="fill-white"
                className="h-[10px] w-[10px]"
              />
            </div>
            <div className="relative z-0 w-[650px] rounded-[29px] bg-[#F5F5F5] p-[20px] shadow-[4px_4px_20px_rgba(0,0,0,0.07)] dark:bg-gray-50 md+:w-[765px] xl:w-[765px]">
              {stock ? (
                <div className="no-scroll-bar h-[min(90vh,550px)] grow overflow-y-auto overflow-y-scroll md:h-[min(90vh,650px)] mx-[-40px] px-[40px]">
                  <div className="flex gap-[20px] md+:gap-[45px]">
                    <div className="flex grow flex-col">
                      <div className="relative mb-5">
                        {stock.endAt && (
                          <div
                            className={`absolute bg-${bgColor} left-[15px] top-[15px] w-max rounded-2xl px-[13px] py-[6px] text-xs text-white`}
                          >
                            <span className="flex items-center">
                              До конца действия:
                              <img className="mx-[6px]" src={time} />
                              <span>
                                {timeToEnd.days
                                  ? `${timeToEnd.days} д ${timeToEnd.hours} ч`
                                  : null}
                                {!timeToEnd.days && timeToEnd.hours
                                  ? `${timeToEnd.hours} ч`
                                  : null}
                                {!timeToEnd.days &&
                                !timeToEnd.hours &&
                                timeToEnd.mins
                                  ? `${timeToEnd.mins} мин`
                                  : null}
                                {!timeToEnd.days &&
                                !timeToEnd.hours &&
                                !timeToEnd.mins &&
                                timeToEnd
                                  ? `Меньше минуты`
                                  : null}
                              </span>
                            </span>
                          </div>
                        )}

                        {stock.image ? (
                          <img
                            className="w-full rounded-2xl object-cover xl:min-h-[410px]"
                            src={stock.image}
                          />
                        ) : (
                          <img
                            className="w-full rounded-2xl object-cover xl:min-h-[410px]"
                            src={mainStock}
                            alt=""
                          />
                        )}
                      </div>

                      <div>
                        <div className="items-center text-gray-50 dark:text-gray-10">
                          <div className="mb-[15px] text-[22px] font-medium text-gray-50 dark:text-white">
                            {stock.title}
                          </div>
                          <div
                            className="whitespace-pre-wrap text-base font-normal [&_a]:text-main [&_li]:ml-5 [&_ol]:list-decimal [&_ul]:list-disc"
                            ref={refDescriptionDesc}
                          />
                          <div
                            className={`mt-[30px] ${
                              !stock.goods.length &&
                              stock.promoCode &&
                              "mb-[60px]"
                            } `}
                          >
                            {stock.promoCode &&
                              branch.order.fields.promoCode?.isUsed && (
                                <div className="absolute bottom-0 z-[20] flex w-full justify-between bg-white py-5 dark:bg-gray-50 rounded-b-[29px] left-0 pl-5 pr-[14px]">
                                  {stock.promoCode.toLowerCase() !==
                                  cart.cartPromoCode.promoCode?.code?.toLowerCase() ? (
                                    <>
                                      <div className="flex items-center justify-center text-base font-medium text-main md:text-xl">
                                        <span>Промокод:</span>
                                        <span className="lines-ellipsis ml-[3px]">
                                          {stock.promoCode}
                                        </span>
                                      </div>
                                      <BaseButton
                                        onClick={() =>
                                          !loading &&
                                          updateCart({
                                            promoCode: stock.promoCode,
                                            raw: cart.raw
                                          })
                                        }
                                        className="rounded-full bg-main h-[45px] px-[24px]"
                                      >
                                        {loading ? (
                                          <Preloader
                                            countOfDot={4}
                                            size="8px"
                                            color="white"
                                            className="flex h-[20px] flex-row items-center gap-2"
                                          />
                                        ) : (
                                          <span className="flex items-center justify-center text-[20px] font-medium text-white">
                                            Применить
                                          </span>
                                        )}
                                      </BaseButton>
                                    </>
                                  ) : (
                                    <>
                                      <div className="flex items-center justify-center text-base font-medium text-main hover:bg-main hover:text-white hover:opacity-100 md:text-xl">
                                        <span>Промокод:</span>
                                        <span className="lines-ellipsis ml-[3px]">
                                          {stock.promoCode}
                                        </span>
                                      </div>
                                      <BaseButton className="pointer-events-none border border-solid border-main text-xl font-medium text-main opacity-50">
                                        <span>Промокод применён</span>
                                      </BaseButton>
                                    </>
                                  )}
                                </div>
                              )}

                            <div className="flex flex-wrap gap-[30px]">
                              {stock.buttons.map(
                                (button: Button, index: number) => (
                                  <BaseButton
                                    key={index}
                                    className="w-[220px] bg-orderbtn text-base font-medium text-main hover:bg-main hover:text-white"
                                  >
                                    <a href={button.url}>{button.title}</a>
                                  </BaseButton>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {stock.goods.length ? (
                    <div
                      className={`mt-4`}
                    >
                      <p className="mb-5 font-medium dark:text-white">
                        Товары, участвующие в акции
                      </p>
                      <div className="relative">
                        <button
                          className={`${isShowLeftArrow ? "lg:flex" : "lg:hidden"} shadow-[0px_7px_12px_-3px_#0000001A] absolute hidden top-[calc(50%-20px)] left-[-40px] z-[5] h-10 w-10  rounded-full items-center justify-center bg-[#fff] dark:bg-gray-50 stroke-gray-60 dark:stroke-[#848484] hover:stroke-main dark:hover:stroke-main transition-[0.2]`}
                          onClick={() => slider.current?.slickPrev()}
                        >
                          <div className="w-[12px] h-[18px] mr-1">
                            <svg
                              width="12"
                              height="18"
                              viewBox="0 0 12 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 2L3 9L10 16"
                                strokeWidth="3"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                        </button>

                        <Slider
                          ref={slider}
                          {...settingsSlider}
                          className="-mx-[20px]"
                        >
                          {stock.goods.map((good: any, index: number) => (
                            <div className="mx-[10px] h-full" key={good}>
                              <Product productId={good} isSwiping={isSwiping} />
                            </div>
                          ))}
                        </Slider>
                        <button
                          className={`${isShowRightArrow ? "lg:flex" : "lg:hidden"} shadow-[0px_7px_12px_-3px_#0000001A] absolute hidden top-[calc(50%-20px)] right-[-40px] z-[5] h-10 w-10 rounded-full items-center justify-center bg-[#fff] dark:bg-gray-50 stroke-gray-60 dark:stroke-[#848484] hover:stroke-main dark:hover:stroke-main transition-[0.2]`}
                          onClick={() => slider.current?.slickNext()}
                        >
                          <div className="rotate-180 w-[12px] h-[18px] ml-1">
                            <svg
                              width="12"
                              height="18"
                              viewBox="0 0 12 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 2L3 9L10 16"
                                strokeWidth="3"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <span />
                  )}
                  <div className={`${stock.promoCode && "pd:mb-[62px] pb-[85px]"}`}/>
                </div>
              ) : (
                <div className="flex h-full items-center justify-center bg-white dark:bg-dark">
                  <Preloader size="10px" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Мобилка */}
      <div
        className={`fixed left-0 top-0 z-[200] block h-screen w-screen sm:hidden ${
          !stockId && "hidden"
        }`}
      >
        <div className="flex h-full w-full flex-col bg-gray-10 dark:bg-gray-50">
          {stock ? (
            <>
              <div className="flex items-center bg-white p-[14px] text-gray-50 shadow-[0px_4px_40px_rgba(0,0,0,0.04)] dark:bg-gray-50 dark:text-gray-10">
                <div onClick={close} className="cursor-pointer">
                  <div className="rotate-180">
                    <ArrowIcon className="h-[14px] w-[8px]" />
                  </div>
                </div>

                <div className="ml-[18px] grow">
                  <div className="text-[24px] font-medium">{stock.title}</div>
                </div>
              </div>

              <div className="overflow-y-auto p-[20px]">
                <div className="relative mb-5 md:mb-[25px]">
                  {stock.endAt ? (
                    <div
                      className={`absolute bg-${bgColor} left-[15px] top-[15px] w-max rounded-2xl px-[13px] py-[6px] text-xs text-white`}
                    >
                      <span className="flex items-center">
                        До конца действия:
                        <img className="mx-[6px]" src={time} />
                        <span>
                          {timeToEnd.days
                            ? `${timeToEnd.days} д ${timeToEnd.hours} ч`
                            : null}
                          {!timeToEnd.days && timeToEnd.hours
                            ? `${timeToEnd.hours} ч`
                            : null}
                          {!timeToEnd.days && !timeToEnd.hours && timeToEnd.mins
                            ? `${timeToEnd.mins} мин`
                            : null}
                          {!timeToEnd.days &&
                          !timeToEnd.hours &&
                          !timeToEnd.mins &&
                          timeToEnd
                            ? `Меньше минуты`
                            : null}
                        </span>
                      </span>
                    </div>
                  ) : null}

                  {stock.image ? (
                    <img className="w-full rounded-2xl" src={stock.image} />
                  ) : (
                    <img
                      className="w-full rounded-2xl"
                      src={mainStock}
                      alt=""
                    />
                  )}
                </div>
                <div className="items-center text-gray-50 dark:text-gray-10">
                  <div className="mb-[15px] hidden text-[22px] font-bold text-gray-60 dark:text-white sm:block">
                    {stock.title}
                  </div>
                  <div
                    className="whitespace-pre-wrap text-base font-normal [&_a]:text-main [&_li]:ml-5 [&_ol]:list-decimal [&_ul]:list-disc"
                    ref={refDescriptionMob}
                  />
                  <div
                    className={`mt-[30px] ${stock?.promoCode && "mb-[20px]"}`}
                  >
                    {stock.promoCode && (
                      <div className="fixed bottom-0 left-0 z-[20] flex w-full justify-center bg-white p-3 dark:bg-dark">
                        {stock.promoCode.toLowerCase() !==
                        cart.cartPromoCode.promoCode?.code?.toLowerCase() ? (
                          <BaseButton
                            onClick={() =>
                              !loading &&
                              updateCart({
                                promoCode: stock.promoCode,
                                raw: cart.raw
                              })
                            }
                            className="w-[100%] border border-solid border-main text-base font-medium text-main active:bg-main active:text-white"
                          >
                            {loading ? (
                              <Preloader
                                countOfDot={4}
                                size="8px"
                                className="flex h-[20px] flex-row items-center gap-2"
                              />
                            ) : (
                              <>
                                <span>Применить промокод:</span>

                                <div className="lines-ellipsis ml-[3px]">
                                  {stock.promoCode}
                                </div>
                              </>
                            )}
                          </BaseButton>
                        ) : (
                          <BaseButton className="pointer-events-none w-[100%] border border-solid border-main text-base font-bold text-main opacity-50">
                            <span>Промокод применён:</span>
                            <div className="lines-ellipsis ml-[3px]">
                              {stock.promoCode}
                            </div>
                          </BaseButton>
                        )}
                      </div>
                    )}

                    <div className="flex flex-col flex-wrap gap-[20px]">
                      {stock.buttons.map((button: Button, index: number) => (
                        <BaseButton
                          key={index}
                          className="w-[100%] bg-orderbtn text-base font-bold text-main"
                        >
                          <a href={button.url}>{button.title}</a>
                        </BaseButton>
                      ))}
                    </div>
                  </div>
                </div>
                {stock.goods.length ? (
                  <div className={`mt-5 ${stock.promoCode && "mb-[70px]"}`}>
                    <p className="mb-5 font-medium dark:text-white">
                      Товары, участвующие в акции
                    </p>
                    <div className="relative">
                      <Slider
                        ref={sliderMobile}
                        {...settingsSliderMobile}
                        className="-mx-[20px]"
                      >
                        {stock.goods.map((good: any, index: number) => (
                          <div className="mx-[10px] h-full" key={good}>
                            <Product
                              productId={good}
                              isSwiping={isSwipingMobile}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                ) : (
                  <span />
                )}
                <div className={`${stock.promoCode && "pd:mb-[62px] pb-[85px]"}`}/>
              </div>
            </>
          ) : (
            <div className="flex h-full items-center justify-center bg-white dark:bg-dark">
              <Preloader size="10px" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function Product({ productId, isSwiping }: any) {
  const { company, branch, allProducts } = useAppContext()
  const { openDetail } = useProductDetailCardContext();
  const { cart, addProductServer, updateProductServer } = useCartContext();
  const [load, setLoad] = useState(false);

  const product = useMemo(() => {
    const product = allProducts[productId];

    if (!product) {
      console.log(`Товар ${productId} не найден в allProducts`);
      return null;
    }

    return product;
  }, [productId]);

  const productCart = useMemo(
    () =>
      cart.products.filter(
        (p: any) =>
          !p.isAdded &&
          !p.isGift &&
          !p.isChosenGift &&
          p.productId == product.id
      )[0],
    [JSON.stringify(cart.products)]
  );

  return (
    <div
      onClick={() => !isSwiping && openDetail(product.id)}
      className="p-[10px] md:p-[12px] flex flex-col gap-[10px] w-[140px] md:w-[154px] bg-white shadow-lg dark:bg-gray-50 rounded-[15px] mb-[40px]"
    >
      {product.image ? (
        <div className="rounded-[17px] overflow-hidden h-[120px] md:h-[130px] min-w-[120px] md:min-w-[130px]">
          <picture className="h-[120px] md:h-[130px]">
            <source
              className="h-[120px] md:h-[130px]"
              type="image/webp"
              srcSet={`${resizeImage(product.image, "125", "webp")} 1x, ${resizeImage(product.image, "300", "webp")} 2x, ${resizeImage(product.image, "400", "webp")}3x`}
            />
            <source
              className="h-[120px] md:h-[130px]"
              type="image/jpeg"
              srcSet={`${resizeImage(product.image, "125", "jpg")} 1x, ${resizeImage(product.image, "300", "jpg")} 2x, ${resizeImage(product.image, "400", "jpg")} 3x`}
            />
            <img
              className="h-[120px] md:h-[130px]"
              src={resizeImage(product.image, "125", "jpg")}
              srcSet={`${resizeImage(product.image, "125", "jpg")} 1x, ${resizeImage(product.image, "300", "jpg")} 2x, ${resizeImage(product.image, "400", "jpg")} 3x`}
              alt=""
            />
          </picture>
        </div>
      ) : (
        <img
          className="w-[120px] md:w-[130px] h-[120px] md:h-[130px] rounded-[17px]"
          src={productImgStub}
          alt=""
        />
      )}

      <div className="-tracking-[.01em] font-medium lines-ellipsis lines-ellipsis--2 text-center h-9 leading-[18px]">
        {product.title}
      </div>

      {productCart ? (
        <div
          onClick={(event) => {
            event.stopPropagation();
            setLoad(true);
            updateProductServer(productCart.uid, { count: 0 }).then(() =>
              setLoad(false)
            );
          }}
          className="w-full h-[34px] flex items-center justify-center rounded-[18px] font-bold cursor-pointer bg-orderbtn text-main"
        >
          {load ? (
            <Preloader
              countOfDot={3}
              color="white"
              size="8px"
              className="min-w-[122px] gap-2"
            />
          ) : (
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 10.0558L6.33333 14.3891L15 2.11133"
                stroke={company.template.mainColor}
                strokeWidth="2.88889"
                strokeLinecap="round"
              />
            </svg>
          )}
        </div>
      ) : (
        <div
          onClick={(event) => {
            event.stopPropagation();
            setLoad(true);
            addProductServer(product.id, [], 1).then(() => setLoad(false));
          }}
          className="flex items-center justify-center w-full h-[35px] border-[2px] border-main text-main rounded-[18px] cursor-pointer -tracking-[.01em] font-bold  duration-500"
        >
          {load ? (
            <Preloader
              countOfDot={3}
              color="white"
              size="8px"
              className="min-w-[122px] gap-2"
            />
          ) : (
            formatCurrency(branch.extended.currency.code, product.price)
          )}
        </div>
      )}
    </div>
  );
}