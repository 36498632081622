import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../contexts/App';

export default function GisWidget() {
  const { branch } = useAppContext()
  const [decodedHtml, setDecodedHtml] = useState('')
  const [isApiWidget, setApiWidget] = useState(false)
  const [id, setId] = useState('')

  useEffect(() => {
    if (branch?.twoGisCode) {
      if (branch?.twoGisCode.indexOf('text/javascript') !== -1) {
        let indexIdStart = branch.twoGisCode.indexOf('id')
        indexIdStart = branch.twoGisCode.indexOf('"', indexIdStart) + 1
        let indexIdEnd = branch.twoGisCode.indexOf('"', indexIdStart)
  
        let id = branch.twoGisCode.substring(indexIdStart, indexIdEnd)
  
        setId(id)
  
        let htmlBase64Start = branch.twoGisCode.indexOf(id, indexIdEnd) + id.length + 1
        htmlBase64Start =  branch.twoGisCode.indexOf('"', htmlBase64Start) + 1
        let htmlBase64End = branch.twoGisCode.indexOf('"', htmlBase64Start)
  
        let htmlBase64 = branch.twoGisCode.substring(htmlBase64Start, htmlBase64End)
  
        setDecodedHtml(atob(htmlBase64))
      } else if (branch?.twoGisCode.indexOf('src') !== -1) {
        setApiWidget(true)
      }
    }
  }, [branch?.twoGisCode])

  if (!branch?.twoGisCode || !(decodedHtml || isApiWidget)) {
    return <></>
  }

  return (
    <>
      {
        isApiWidget ?
        <div
          dangerouslySetInnerHTML={{
            __html: branch.twoGisCode
          }}
        /> :
        <iframe
          id={id}
          width="150px"
          height="50px"
          srcDoc={"<style>html { overflow: hidden; }</style>" + decodedHtml} // Здесь передаётся декодированный HTML
          sandbox="allow-scripts allow-same-origin allow-popups"
        ></iframe>
      }
    </>
  )
}