import React, { useState, useEffect, useMemo } from 'react'
import { useAppContext } from '../../../../contexts/App'
import { useAddressContext } from '../../../../contexts/AddressContext'
import { TextInput } from '../../../common/form/input/TextInput'
import AddressSelectorPopup from '../../../common/popup/citites/AddressSelector'

export default function AddressForm({data, updateField, errors, deliveryAddress, pickupPoint}: any) {
  const { city, branch, company } = useAppContext()

  const {addressError} = useAddressContext()
  const [addressPopupActive, setAddressPopupActive] = useState(false)

  return (
    <>
      <div className='text-main font-medium text-[20px] my-5'>{ data.deliveryType === 'courier' ? 'Адрес доставки' : 'Адрес самовывоза' }</div>
      <div className='bg-gray-20 dark:bg-gray-40 h-[34px] rounded-[10px] flex items-center justify-between px-[22px] mb-[15px]'>
        <div className='lines-ellipsis text-[14px] text-gray-60 dark:text-white'>{data.deliveryType === 'courier' ? deliveryAddress?.name : pickupPoint?.title}</div>
        <div className='text-[14px] text-main cursor-pointer' onClick={() => setAddressPopupActive(true)}>Изменить</div>
      </div>
      {
        data.deliveryType === 'courier' && 
        <div className="flex gap-[15px] mb-[15px]">
          {
            branch.order.fields?.entrance?.isUsed &&
            <TextInput
              className='w-full'
              name={'entrance'}
              value={data.entrance || ''}
              onChange={(value) => updateField('entrance', value)}
              placeholderInput={`Подъезд${branch.order.fields?.entrance.isRequired ? '*' : ''}`}
              errors={errors}
              errorLabel={false}
            />
          }
          {
            branch.order.fields?.floor?.isUsed &&
            <TextInput
              className='w-full'
              name={'floor'}
              value={data.floor || ''}
              onChange={(value) => {
                if (/^[0-9.]+$/.test(value)) {
                  updateField('floor', value)
                }
              }}
              placeholderInput={`Этаж${branch.order.fields?.floor.isRequired ? '*' : ''}`}
              errors={errors}
              errorLabel={false}
              type='number'
            />
          }
          {
            branch.order.fields?.flat?.isUsed &&
            <TextInput
              className='w-full'
              name={'flat'}
              value={data.flat || ''}
              onChange={(value) => updateField('flat', value)}
              placeholderInput={`Квартира${branch.order.fields?.flat.isRequired ? '*' : ''}`}
              errors={errors}
              errorLabel={false}
            />
          }
        </div>
      }

      {
        addressError?.message &&
        <div className='text-yellow text-xs'>{addressError?.message}</div>
      }
      {
        (data.deliveryType === 'courier' && data.deliveryZoneId == null && data.deliveryAddress && company.isCustomerAddressMustBeInDeliveryZone) &&
        <div className='text-yellow text-xs'>Выбранный адрес не входит в зону доставки</div>
      }
      {
        (data.deliveryType === 'courier' && data.branchId != null && data.branchId !== branch.id && company.isCustomerAddressMustBeInDeliveryZone) &&
        <div className='text-yellow text-xs'>Выбранный адрес входит в зону доставки другого филиала</div>
      }
      {
        errors?.deliveryType?.length && 
        <div className='text-yellow text-xs'>{ errors?.deliveryType?.at(0) }</div>
      }

      {
        errors?.deliveryAddress?.length && 
        <div className='text-yellow text-xs'>{ errors?.deliveryAddress?.at(0) }</div>
      }

      <AddressSelectorPopup
        isActive={addressPopupActive}
        setActive={setAddressPopupActive}
      />
    </>
  )
}
